import {Api} from "../../api";
import {cacheFunctionData} from '/utils'

export const fetchContacts = () => {
    return async (dispatch) => {
        const ITEMS = await cacheFunctionData(Api.location.getContacts, 'contacts_4')
        dispatch({type: 'SET_CONTACTS', payload: ITEMS})
    }
}

export const setContactsData = (data) => {
    return (dispatch) => {
        dispatch({type: 'SET_CONTACTS_DATA', payload: data})
    }
}